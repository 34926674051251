@font-face {
  font-family: 'ProximaNovaAltLight';
  src: url('../fonts/ProximaNovaAltLight.eot');
  src: url('../fonts/ProximaNovaAltLight.eot') format('embedded-opentype'),
    url('../fonts/ProximaNovaAltLight.woff2') format('woff2'),
    url('../fonts/ProximaNovaAltLight.woff') format('woff'),
    url('../fonts/ProximaNovaAltLight.ttf') format('truetype'),
    url('../fonts/ProximaNovaAltLight.svg#ProximaNovaAltLight') format('svg');
}
@font-face {
  font-family: 'ProximaNovaSemibold';
  src: url('../fonts/ProximaNovaSemibold.eot');
  src: url('../fonts/ProximaNovaSemibold.eot') format('embedded-opentype'),
    url('../fonts/ProximaNovaSemibold.woff2') format('woff2'),
    url('../fonts/ProximaNovaSemibold.woff') format('woff'),
    url('../fonts/ProximaNovaSemibold.ttf') format('truetype'),
    url('../fonts/ProximaNovaSemibold.svg#ProximaNovaSemibold') format('svg');
}
