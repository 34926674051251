#header {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
}
#header.mainClass .header-top {
  display: inline-block;
  padding: 20px 0;
  width: 100%;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
#header.scrollClass .header-top {
  display: inline-block;
  padding: 0 0;
  width: 100%;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
#header.scrollClass {
  -webkit-box-shadow: 0 0 4px rgb(64, 64, 64);
  box-shadow: 0 0 12px rgba(64, 64, 64, 0.5);
}
#header.mainClass img.menuLogo {
  max-width: 263px;
  transition: 0.5s;
  display: block;
  margin-top: -10px;
  /*background-color: black;*/
}
#header.scrollClass img.menuLogo {
  width: 200px;
  padding-top: 0;
}
#header.mainClass .mainNav .mainNavInner a {
  margin-top: 15px;
}
#header.scrollClass .mainNav .mainNavInner a {
  margin-top: 10px;
}
#header.mainClass .headerButton {
  margin-top: 10px;
}
#header.scrollClass .headerButton {
  margin-top: 0px;
}
#header .header-top .MuiToolbar-gutters {
  padding: 0 0 !important;
}
#header .header-top button.MuiButton-root {
  min-width: inherit;
}
.headerButton {
  padding: 0 0 0 10px;
  /* vertical-align: top; */
  text-align: center;
}
.scrollShow {
  display: block !important;
}
.scrollHide {
  display: none !important;
}
.menu-act {
  padding: 70px 0;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  z-index: 999;
}
.menu-act ul {
  margin: 0 0;
  padding: 0 0;
}
.menu-act ul li {
  position: relative;
  display: block;
}
.menu-act ul li a {
  padding-bottom: 0;
  float: none;
  display: block;
  color: #333;
  text-align: left;
  padding: 14px 20px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.menu-act ul li.loginOuter a,
.menu-act ul li.get_startedOuter a {
  padding: 0 0;
}
.menu-act ul li a:hover {
  color: #fab115;
  border-bottom-color: #fab115;
  text-decoration: none;
}
.menu-act ul li button.login {
  border: none;
  margin: 0 0;
  padding: 14px 20px;
  border-radius: 0;
  display: block;
  width: 100%;
  line-height: 1.2;
}
.menu-act ul li button.login .MuiButton-label {
  text-align: left;
}
.menu-act ul li button.get_started {
  border: none;
  margin: 0 0;
  padding: 14px 20px;
  display: block;
  width: 100%;
  border-radius: 0;
  height: auto;
  text-align: left;
  line-height: 1.2;
}
.menu-act .close-button button {
  float: right;
  margin: -52px 19px 30px;
  color: #333;
  padding: 0;
}
.menu-act .close-button button svg.MuiSvgIcon-root {
  border-radius: 0;
  font-size: 40px;
}
.scrollClass img.menuLogo {
  padding: 10px 0 0 0;
}

@media only screen and (max-width: 767px) {
  #header.mainClass img.menuLogo {
    max-width: 210px;
  }
  .wrapper-mobile {
    display: flex;
    justify-content: flex-end;
  }
}
