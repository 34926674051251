.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/*---- Header CSS Start ----*/

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli:300,400,500,600,700,800,900&display=swap');

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
html * {
  max-height: 1000000px;
}
body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  font-weight: 400;
  line-height: 1.42857;
}

html body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding-top: 110px;
  overflow-x: hidden;
  background-color: #f5f7f9;
}
html body.dashboard {
  padding-top: 0px !important;
}
a {
  -webkit-transition: color 0.2s ease;
  -moz-transition: color 0.2s ease;
  -ms-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  transition: color 0.2s ease;
  text-decoration: none;
  color: #333;
}
a:focus {
  outline: none;
  outline-offset: 0;
}
a:hover {
  color: #fbb116;
  text-decoration: none !important;
}
img {
  max-width: 100%;
  height: auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
body {
  /* background: url(./images/home-banner.jpg) no-repeat; */
  background-position: top center;
  background-size: 100% auto;
  font-size: 100%;
  color: #000;
  line-height: 100%;
  font-style: normal;
  font-weight: normal;
}
/* #header {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
}
#header.mainClass .header-top {
  display: inline-block;
  padding: 20px 0;
  width: 100%;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
#header.scrollClass .header-top {
  display: inline-block;
  padding: 0 0;
  width: 100%;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
#header.scrollClass {
  -webkit-box-shadow: 0 0 4px rgb(64, 64, 64);
  box-shadow: 0 0 12px rgba(64, 64, 64, 0.5);
}
#header.mainClass img.menuLogo {
  max-width: 263px;
  transition: 0.5s;
  display: block;
  background-color: black;
}
#header.scrollClass img.menuLogo {
  width: 200px;
  padding-top: 0;
}
#header.mainClass .mainNav .mainNavInner a {
  margin-top: 15px;
}
#header.scrollClass .mainNav .mainNavInner a {
  margin-top: 10px;
}
#header.mainClass .headerButton {
  margin-top: 10px;
}
#header.scrollClass .headerButton {
  margin-top: 0px;
}
#header .header-top .MuiToolbar-gutters {
  padding: 0 0 !important;
} */
footer .copyRightTxt a {
  color: white;
  text-decoration: none;
}
.MuiIconButton-colorInherit {
  display: none;
}
.mainNav .mainNavInner a {
  color: #333333;
  float: left;
  font-family: 'ProximaNovaSemibold';
  font-size: 17px;
  font-weight: normal;
  padding: 0 0 0 25px;
  text-transform: capitalize;
  cursor: pointer;
}
.mainNav .mainNavInner a:hover {
  color: #fab115;
  text-decoration: none;
}
button.iconButton {
  display: none;
}
button.login {
  color: #1957a6;
  background: #fff;
  box-shadow: none;
  border: 1px solid #999;
  margin-right: 10px;
  margin-left: 10px;
}
button.login:hover {
  color: #fff !important;
  background-color: #1957a6 !important;
  border-color: transparent;
}
button.get_started {
  font-size: 19px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  width: auto;
  min-width: auto;
  padding: 0 26px;
  color: #fff;
  box-shadow: none;
  background: #fbb116;
}
button.get_started:hover {
  color: #fff !important;
  background-color: #1957a6 !important;
  border-color: transparent;
}
/* .headerButton {
  padding: 0 0 0 10px;
  /* vertical-align: top; 
  text-align: center;
}
.scrollShow {
  display: block !important;
}
.scrollHide {
  display: none !important;
}
.menu-act {
  padding: 70px 0;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  z-index: 999;
}
.menu-act ul {
  margin: 0 0;
  padding: 0 0;
}
.menu-act ul li {
  position: relative;
  display: block;
}
.menu-act ul li a {
  padding-bottom: 0;
  float: none;
  display: block;
  color: #333;
  text-align: left;
  padding: 14px 20px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.menu-act ul li.loginOuter a,
.menu-act ul li.get_startedOuter a {
  padding: 0 0;
}
.menu-act ul li a:hover {
  color: #fab115;
  border-bottom-color: #fab115;
  text-decoration: none;
}
.menu-act ul li button.login {
  border: none;
  margin: 0 0;
  padding: 14px 20px;
  border-radius: 0;
  display: block;
  width: 100%;
  line-height: 1.2;
}
.menu-act ul li button.login .MuiButton-label {
  text-align: left;
}
.menu-act ul li button.get_started {
  border: none;
  margin: 0 0;
  padding: 14px 20px;
  display: block;
  width: 100%;
  border-radius: 0;
  height: auto;
  text-align: left;
  line-height: 1.2;
}
.menu-act .close-button button {
  float: right;
  margin: -52px 19px 30px;
  color: #333;
  padding: 0;
}
.menu-act .close-button button svg.MuiSvgIcon-root {
  border-radius: 0;
  font-size: 40px;
}
.scrollClass img.menuLogo {
  padding: 10px 0 0 0;
} */
/* #header .header-top button.MuiButton-root {
  min-width: inherit;
} */

/* Header CSS End */

/* Footer Section Start */

.inouiry-section h2 {
  margin-bottom: 20px;
  z-index: 32;
  font-size: 40px;
  color: #000;
  font-family: 'ProximaNovaSemibold';
  font-weight: 500;
  position: relative;
}
.inouiry-section h2 span {
  color: #1957a6;
}
.inouiry-section h2::after {
  background: #1957a6;
  bottom: -9px;
  content: '';
  height: 2px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: auto;
  width: 80px;
}
.inouiry-section p {
  font-size: 19px;
  color: #333;
  text-align: center;
  display: table;
  margin: 27px auto 20px;
  line-height: 140%;
  font-weight: 400;
  font-family: 'Muli', sans-serif;
}
.contact-form {
  margin: 0 auto;
  max-width: 680px;
}
.inouiry-section {
  padding-bottom: 0;
  background-color: #fff;
}
.inouiry-section .inouiry-wrap {
  background: #fff;
  padding-bottom: 40px;
  display: inline-block;
  width: 100%;
  border-radius: 0 0 12px 12px;
  position: relative;
  margin: 0 0;
}
.inouiry-section .inouiry-wrap::after {
  background: #e3f3fd;
  border-radius: 0 0 12px 12px;
  bottom: -17px;
  content: '';
  height: 17px;
  left: 10px;
  position: absolute;
  right: 10px;
}
.inouiry-section .inouiry-wrap::before {
  background: #5d8bc4;
  border-radius: 0 0 12px 12px;
  bottom: -32px;
  content: '';
  height: 17px;
  left: 20px;
  position: absolute;
  right: 20px;
}
footer#footer {
  background: #1957a6;
  margin-top: -180px;
  clear: both;
  display: block;
  min-height: 450px;
  padding-top: 280px;
  width: 100%;
}
.contact-form button.submit-btn {
  font-size: 24px;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  min-width: 160px;
  background: #fbb116;
  border: 0 none;
  -webkit-box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.175);
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.175);
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: 'ProximaNovaSemibold';
  font-weight: normal;
  text-decoration: none;
  width: auto;
  text-align: center;
  margin: 20px auto 0;
  display: table;
}
.contact-form button.submit-btn:hover {
  background: #1957a6;
}
.contact-form p {
  font-size: 18px;
  text-align: center;
  display: table;
  margin: 0 0;
  line-height: 140%;
  font-weight: 400;
  font-family: 'Muli', sans-serif;
  background: #1957a6;
  color: white;
  padding: 10px 15px;
}
ul.social-icon {
  text-align: center;
  margin: 0 0 !important;
  padding: 0 0;
}
ul.social-icon li {
  display: inline-block;
  vertical-align: middle;
  margin: 20px 2px 0 0;
}
ul.social-icon li .MuiAvatar-colorDefault {
  color: #fafafa;
  background-color: transparent;
  border: 1px solid white;
}
ul.social-icon li .MuiAvatar-colorDefault a {
  color: white;
}
#footer .footer-top > div h5 {
  color: #fff;
  font-size: 20px;
  position: relative;
  margin: 0 0 30px;
  padding-bottom: 10px;
}
#footer .footer-top > div h5::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 35px;
  background: #fbb116;
}
ul.menu-link,
ul.contact {
  margin: 0 0 !important;
  padding: 0 0;
}
.footer-top ul.menu-link li a:hover,
.footer-top ul.menu-link li.active a {
  color: #fbb116;
}
.footer-top ul.menu-link li,
.footer-top ul.contact li {
  list-style: none;
}
.footer-top ul.menu-link li a {
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  font-family: 'Muli', sans-serif;
  display: table;
  margin: 0 0 20px;
  padding-left: 13px;
  position: relative;
  font-weight: 300;
  text-decoration: none;
  cursor: pointer;
}
.footer-top ul.menu-link li a:hover::after,
.footer-top ul.menu-link li.active a::after {
  background: #fbb116;
}
.footer-top ul.menu-link li a::after {
  position: absolute;
  left: 0;
  height: 5px;
  width: 5px;
  top: 5px;
  font-size: 50px;
  background: #fff;
  content: '';
  border-radius: 50%;
}
ul.contact li {
  display: flex;
  flex-direction: row;
}
ul.contact li p {
  color: #fff;
  font-weight: 300;
  margin: 0;
  text-align: left;
  font-family: 'Muli', sans-serif;
  font-size: 17px;
  line-height: 1.2;
}
ul.contact li div.contactIcons {
  background: transparent;
  border: 1px solid white;
  color: #fbb116 !important;
  margin-right: 7px;
}
ul.contact li p a {
  /*font-size: 17px;*/
  color: #fff;
  text-align: left;
  font-weight: 300;
  font-family: 'Muli', sans-serif;
  display: inline-block;
  margin-top: 12px;
}
ul.contact li {
  margin-bottom: 18px;
}
footer .footer-top div p {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Muli', sans-serif;
}
ul.social-icon li a:hover {
  color: #fbb116;
  border-color: #fbb116;
}
.footer-bottom > p {
  border-top: 1px solid #6a8cac;
  margin: 40px 0 0;
  padding: 20px;
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #fff;
  font-weight: 300;
  font-family: 'Muli', sans-serif;
}
footer .copyRightTxt a {
  color: white;
  text-decoration: none;
}
p.Mui-error {
  color: red !important;
  font-size: 11px !important;
  background: none;
  text-align: left;
  padding: 5px 0 !important;
  margin: 0 0 !important;
}
p.MuiFormHelperText-contained {
  color: red !important;
  font-size: 11px !important;
  background: none;
  text-align: left;
  padding: 5px 0 !important;
  margin: 0 0 !important;
}
p.dialog-error {
  color: red !important;
  font-size: 14px !important;
  text-align: center !important;
  padding: 10px 0 !important;
  margin: 15px 0 0 0 !important;
  background: transparent;
  display: block;
  border: 1px solid red;
}
p.success {
  color: green !important;
  font-size: 14px !important;
  text-align: center !important;
  padding: 10px 0 !important;
  margin: 15px 0 0 0 !important;
  background: transparent;
  display: block;
  border: 1px solid green;
}
p.default_p {
  background: transparent !important;
}
a.active1 {
  color: #007bff !important;
}

/* Footer Section End */

/* List items */
/* ol {
  counter-reset: item;
}
ol li {
  display: block;
}

ol li:before {
  content: counter(item) '. ';
  counter-increment: item;
  font-weight: bold;
} */

ol.bold {
  font-weight: bold;
}
ol.list li {
  margin-bottom: 15px;
}
ol li span {
  font-weight: normal;
}
.term-heading {
  font-size: 1.25rem;
  font-family: Muli;
  font-weight: 500;
  line-height: 1.6;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 20px;
  display: inline-block;
}
h6.term-heading {
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 20px;
  text-transform: uppercase;
}

ol.dsa {
  counter-reset: item;
  padding-left: 20px;
}
ol.dsa li {
  display: block;
}
ol.dsa li:before {
  content: counters(item, '.') '. ';
  counter-increment: item;
}
ol.dsa li.has-heading::before {
  font-size: 1.25rem;
  font-family: Muli;
  font-weight: 500;
  line-height: 1.6;
  text-transform: uppercase;
  font-weight: bold;
}

ol.dsa li span.heading {
  font-weight: bold;
}

ol.child-3 {
  counter-reset: item;
}
ol.child-3 li:before {
  content: '(' counter(item, lower-roman) ') ';
  counter-increment: item;
}

ol.roman {
  counter-reset: item;
  list-style: none;
  padding-left: 20px;
}

ol.roman li:before {
  content: '(' counter(item, lower-roman) ') ';
  counter-increment: item;
}
