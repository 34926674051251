body.log-in #wrapper #header .container.MuiContainer-maxWidthLg,
body.getStarted #header .container.MuiContainer-maxWidthLg {
  max-width: 1280px;
}

@media only screen and (max-width: 767px) {
  .chart-step canvas {
    /* height: 300px !important; */
  }
}

.chart-step {
  position: relative;
}
