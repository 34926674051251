@media only screen and (max-width: 1330px) {
  .mainNav .mainNavInner a {
    padding: 0 0 0 15px;
  }
  /* button.get_started {
    font-weight: 700;
    height: 60px;
    line-height: 40px;
    font-size: 18px;
    width: auto;
    min-width: auto;
    padding: 0 26px;
    color: #fff;
    box-shadow: none;
    background: #fbb116;
  } */
  .MuiContainer-fixed {
    max-width: inherit !important;
  }
  body.about-us .caption,
  body.howItWork .caption,
  body.howItWork .quesion,
  body.ourproject .caption {
    left: 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
  body.about-us .inner.banner-section .container,
  body.howItWork .inner.banner-section .container,
  body.quesion .inner.banner-section .container,
  body.ourproject .inner.banner-section .container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  body.home,
  body.howItWork,
  body.about-us,
  body.ourproject,
  body.contact-us,
  body.question {
    background-size: inherit;
  }
}
@media screen and (min-device-width: 960px) and (max-device-width: 1199px) {
  .mainNav .mainNavInner a {
    font-size: 14px;
    padding: 0 0 0 32px;
  }
  #header.mainClass img.menuLogo {
    max-width: 230px;
    transition: 0.5s;
    display: block;
    /*background-color: black;*/
  }
  .headerButton {
    padding: 0 0;
  }
  button.login {
    margin-left: -60px;
  }

  button.get_started {
    font-weight: 700;
    /* height: 60px; */
    /* line-height: 40px; */
    /* font-size: 18px; */
    /* width: auto; */
    /* min-width: auto; */
    /* padding: 0 10px; */
    /* margin-right: 10px; */
    margin-left: 10px;
    color: #fff;
    box-shadow: none;
    background: #fbb116;
  }
  .banner-section .MuiGrid-grid-md-8 {
    max-width: 60%;
    flex-basis: 60%;
  }
  .banner-section .MuiGrid-grid-md-4 {
    max-width: 40%;
    flex-basis: 40%;
  }
  section.home-banner .home-banner-caption h2 {
    font-size: 40px !important;
  }
  section.home-banner .home-banner-caption h2 strong {
    font-size: 75px;
  }
  section.home-banner .home-banner-caption h2 span {
    font-size: 40px;
  }
  .home-page-work .hwo-it-works-bg {
    border-radius: 0;
    padding: 60px 50px;
    margin-bottom: 45px;
  }
  .home-page-work
    .hwo-it-works-bg
    .hwo-it-step:nth-child(3)
    .images-step::after {
    top: -195px;
  }
  .hwo-it-step:last-child .step-content h3::after {
    left: -44px;
  }
  section.home-banner .calculator-main {
    padding: 34px 20px 38px;
  }
  .investItemMain .investContentbox h3 {
    font-size: 22px;
  }
  .investItemMain .investContentbox p {
    font-size: 16px;
    min-height: 90px;
  }
  .who-we-are.left > ul li {
    float: left;
    list-style: none;
    width: 185px;
  }
  .who-we-are-block .who-we-are.left::after {
    background-size: 100% auto;
    height: 350px;
    left: 0;
    width: 350px;
  }
  .who-we-are-block .who-we-are .small-logo {
    position: relative;
  }
  .who-we-are.left > ul li:nth-child(2n) {
    margin-right: -25px;
    margin-top: 30px;
  }
  .who-we-are.left > ul li:nth-child(3n) {
    margin-right: -30px;
    margin-top: 30px;
  }
  body.ourproject .solar-installations .hwo-it-works-bg {
    padding: 40px 10px 0 10px;
    /* margin: 0 0; */
  }
  body.ourproject
    .installations-main
    .installations-blocks
    .invest-contentbox
    h3 {
    font-size: 18px;
  }
  body.ourproject
    .installations-main
    .installations-blocks
    .invest-contentbox
    button.button {
    padding: 0 20px;
    font-size: 14px;
    min-width: inherit;
    height: inherit;
    line-height: 42px;
  }
  body.ourproject .caption h1 {
    font-size: 50px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.5px;
    margin-left: 0;
    margin-bottom: 9px;
    margin-top: 115px;
  }
  body.ourproject section.solar-site h2 {
    font-size: 24px;
  }
  body.question .hwo-it-works-bg.faqs {
    padding: 60px 25px 60px;
  }
  body.question .hwo-it-works-bg.faqs .MuiBox-root {
    padding: 0 0;
  }
  body.howItWork section.invest-product .invest-pro-inner table {
    width: 100%;
  }
  body.howItWork section.hwo-it-works-last .hwo-it-step-bottom .hwoBottomInner {
    height: auto;
    min-height: 375px;
  }
  body.getStarted .get-started-section .get-started-block .content-left h2,
  body.getStarted .get-started-section .get-started-block .content-rigth h2 {
    font-size: 24px;
  }
  body.howItWork
    .invest-process
    .invest-inner
    .process-list:nth-child(2n + 2)
    .process-item {
    margin-top: 30px;
  }

  /* About Section */
  body.about-us .inner.banner-section .container,
  body.howItWork .inner.banner-section .container,
  body.ourproject .inner.banner-section .container {
    position: relative;
  }
  body.about-us .inner.banner-section,
  body.howItWork .inner.banner-section,
  body.ourproject .inner.banner-section {
    min-height: 200px;
  }
  body.about-us .caption h1,
  body.howItWork .caption h1,
  body.ourproject .caption h1 {
    font-size: 34px;
    margin-top: 40px;
  }
  body.about-us
    section.our-mission-section
    .our-mission-bg
    .mission-main
    .caption-main
    h2 {
    font-size: 30px;
  }
  body.about-us
    section.our-mission-section
    .our-mission-bg
    .mission-main
    .caption-main
    p {
    font-size: 14px;
  }
  body.about-us .caption,
  body.howItWork .caption,
  body.ourproject .caption {
    height: 230px;
    left: 0;
    position: relative;
  }
  body.about-us .caption p,
  body.howItWork .caption p,
  body.ourproject .caption p {
    font-size: 16px;
  }
  body.about-us .our-detail .detail-section .detail-item .detail-cont p {
    font-size: 16px;
  }
  body.about-us .timeline-section .timeline-main h2,
  body.about-us section.solar-capacity h2,
  body.about-us section.company-value h2 {
    font-size: 30px;
  }
  body.about-us .timeline-section .timeline-block .time-caption p {
    font-size: 16px;
  }
  body.about-us .solar-capacity .soalar-capacity-cont p {
    font-size: 16px;
    min-height: 175px;
  }
  body.about-us .company-value .value-section .value-item .value-cont h4 {
    font-size: 20px;
  }
  body.about-us .company-value .value-section .value-item .value-cont p {
    font-size: 14px;
  }
  body.about-us .mission-main .mis-img a p {
    font-size: 10px;
  }
  body.about-us .mission-main .mis-img a span {
    font-size: 16px;
  }
  /* About Section */

  body.contact-us .inner.banner-section .container {
    position: relative;
  }
  body.contact-us .inner.banner-section {
    min-height: 150px;
  }
  body.contact-us .caption h1 {
    font-size: 34px;
    margin-top: 40px;
  }
  body.contact-us .caption {
    height: auto;
    left: 0;
    position: relative;
  }
  body.contact-us .caption p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
}

@media only screen and (max-width: 959px) {
  body.home,
  body.howItWork,
  body.about-us,
  body.ourproject,
  body.contact-us,
  body.dashboard,
  body.question {
    background-size: inherit;
  }
  section.home-banner .home-banner-caption {
    padding-top: 50px;
  }
  section.home-banner .home-banner-caption h2 strong {
    font-size: 45px;
  }
  section.home-banner .home-banner-caption h2 {
    font-size: 30px !important;
    margin: 0 0;
  }
  section.home-banner .home-banner-caption h2 span {
    font-size: 30px;
  }
  section.home-banner .calculator-main {
    max-width: inherit;
  }
  .banner-calculator {
    padding-top: 12px;
  }
  .home-page-work .how-it-header {
    font-size: 17px;
    padding: 20px 0;
  }
  section.home-banner .caption {
    padding: 0 0px 40px;
  }
  body {
    background-size: inherit;
  }
  .home-page-work .hwo-it-works-bg {
    padding: 30px;
    margin-bottom: 40px;
  }
  .hwo-it-works-bg > h2 {
    font-size: 23px;
    text-align: center;
    margin-bottom: 15px;
  }
  .images-step.mobile-img {
    display: block;
    margin-bottom: 20px;
    text-align: center;
  }
  .images-step.mobile-img-hide {
    display: none;
  }
  .hwo-it-step:first-child .step-content h3::before,
  .hwo-it-step:first-child .step-content h3::after {
    display: none;
  }
  .home-page-work .hwo-it-works-bg .hwo-it-step h3 {
    font-size: 20px;
    text-align: center;
    display: block;
  }
  .home-page-work .hwo-it-step:first-child .step-content h3 strong,
  .home-page-work .step-content h3 strong {
    display: block;
    text-align: center;
    position: initial;
  }
  .how-it-works-bg .hwo-it-step:first-child .images-step::before,
  .how-it-works-bg .hwo-it-step:first-child .images-step::after {
    display: none;
  }
  .hwo-it-works-bg .hwo-it-step:first-child .images-step::before,
  .hwo-it-works-bg .hwo-it-step:first-child .images-step::after {
    display: none;
  }
  .how-it-works-section .MuiContainer-fixed,
  .invest-with .MuiContainer-fixed,
  .who-we-are-section .MuiContainer-fixed {
    max-width: inherit;
  }
  .hwo-it-works-bg .hwo-it-step:nth-child(2n) .step-content {
    position: initial;
    padding: 0 0;
  }
  .step-content h3::after {
    display: none;
  }
  .home-page-work .hwo-it-step:first-child .step-content h3 strong {
    display: block;
    text-align: center;
    position: initial;
  }
  .hwo-it-works-bg > h2::after,
  .home-page-work
    .hwo-it-works-bg
    .hwo-it-step:nth-child(3)
    .images-step::after {
    display: none;
  }
  .hwo-it-works-bg .hwo-it-step:nth-child(3) .step-content {
    padding: 0 0;
  }
  .hwo-it-step:not(:first-child) .step-content strong {
    margin-top: 0px;
  }
  .invest-with .invest-us-inner h2 {
    font-size: 23px;
    text-align: center;
  }
  .investItemMain .investContentbox h3 {
    font-size: 22px;
  }
  .investItemMain .investContentbox h3 br {
    display: none;
  }
  .who-we-are-section h2 {
    font-size: 23px;
    text-align: center;
    margin: 0 auto 20px;
  }
  .who-we-are-block .who-we-are {
    max-width: 510px;
    margin: 70px auto 0;
  }
  .who-we-are-block .who-we-are .small-logo {
    right: 0;
    bottom: 0;
    display: block !important;
  }
  .who-we-are-section .who-we-are-block .who-we-are.left > ul li {
    float: none;
    display: inline-block;
    margin: 0px -5px 0;
    width: 180px;
  }
  .who-we-are-section .who-we-are-block .who-we-are.left > ul li:first-child {
    margin: -50px auto 0;
    display: table;
    width: auto;
  }
  .who-we-are.left > ul li:nth-child(2n),
  .who-we-are.left > ul li:nth-child(3n) {
    margin-top: 105px !important;
    width: 205px !important;
  }
  .who-we-are-block .who-we-are.right {
    width: 100%;
    position: relative;
    background: none;
    padding: 0;
    margin-top: 45px;
  }
  .who-we-are-block .who-we-are.right h3 {
    margin: 0 auto 15px;
    font-size: 23px;
    text-align: center;
  }
  .who-we-are-block .who-we-are.right .button {
    margin: 10px auto 0;
    display: block;
  }
  .our-solar-step-block .our-solar-step h3 {
    display: none;
  }
  .our-solar-step-block .our-solar-step div.images-step.mobile-img {
    position: relative;
    padding: 0 25px;
  }

  .our-solar-step-block .our-solar-step div.images-step.mobile-img::after {
    background: #fff;
    border-radius: 10px 17px 10px 15px;
    top: -7px;
    content: '';
    left: 39px;
    position: absolute;
    right: 17px;
    z-index: -1;
    bottom: 10px;
    border: 4px solid #fbb116;
    border-left: 0;
    border-bottom: 0;
  }
  .our-project-table {
    display: block;
    margin: 20px auto;
    width: 100%;
    clear: both;
  }
  .step-content2 button.button {
    display: block;
    margin: 0 auto;
  }
  .our-solar-step-block .our-solar-step {
    margin: 30px 0 0;
  }
  .our-solar-section h2 {
    font-size: 23px;
    text-align: center;
    margin: 0 auto 20px;
  }
  .monitor-investment .MuiContainer-fixed {
    max-width: inherit;
  }
  .monitor-investment .step-images-step.mobile-img-hide {
    display: none;
  }
  .our-dashboard-block .step-content h2 {
    font-size: 23px;
    text-align: center;
    margin: 0 auto 30px;
  }
  .our-dashboard-block .step-content h2::after {
    margin: 0 auto;
    right: 0;
  }
  .step-content button.button {
    display: block;
    margin: 0 auto;
  }
  .get-started-section.MuiContainer-fixed {
    max-width: inherit;
  }
  .get-started-section.MuiContainer-fixed .content-left {
    padding-right: 0 !important;
  }
  .get-started-section.MuiContainer-fixed .get-started-block .content-right {
    padding: 0 !important;
  }
  .get-started-section .get-started-block .content-left h2,
  .get-started-section .get-started-block .content-right h2 {
    font-size: 23px;
    margin: 0 auto 30px;
    text-align: center;
  }
  .get-started-section .get-started-block .content-left h2::after,
  .get-started-section .get-started-block .content-right h2::after {
    right: 0;
  }
  .get-started-block .content-right {
    background: none;
  }
  .inouiry-section .MuiContainer-fixed {
    max-width: inherit;
  }
  .inouiry-section h2 {
    font-size: 23px;
    text-align: center;
    margin: 0 auto 30px;
  }
  .contact-form {
    padding: 0 10px;
  }
  footer#footer .MuiContainer-fixed {
    max-width: inherit;
  }
  ul.social-icon {
    text-align: left;
  }
  .footer-logo {
    display: none;
  }
  body.ourproject .caption,
  body.question .caption {
    height: 300px;
    left: 0;
    margin: auto 0;
    max-width: 515px;
    position: absolute;
    /* top: 0; */
    vertical-align: middle;
    right: 0;
    margin-right: auto;
    margin-left: auto;
  }
  body.ourproject .caption h1,
  body.question .caption h1 {
    text-align: center;
    font-size: 50px;
  }
  body.question .hwo-it-works-bg.faqs {
    padding: 60px 25px 60px;
  }
  body.question .hwo-it-works-bg.faqs .MuiBox-root {
    padding: 0 0;
  }
  body.howItWork .invest-process::before {
    display: none;
  }
  body.howItWork section.invest-product .invest-pro-inner table {
    width: 100%;
  }
  body.howItWork section.invest-product .MuiContainer-fixed {
    max-width: inherit;
  }
  body.howItWork .hwo-it-works-bg .hwo-it-step:first-child .images-step {
    background: none;
    padding-bottom: 20px;
  }
  body.howItWork .hwo-it-works-bg .hwo-it-step:nth-child(2n) .step-content {
    background: none;
    padding-bottom: 20px;
  }
  body.howItWork
    .hwo-it-works-bg
    .hwo-it-step:nth-child(2n)
    .step-content::before {
    display: none;
  }
  body.howItWork
    .hwo-it-works-bg
    .hwo-it-step:nth-child(2n)
    .step-content::after {
    display: none;
  }
  body.howItWork .hwo-it-works-bg .hwo-it-step:last-child .images-step {
    background: none;
  }
  body.howItWork .hwo-it-works-bg > h2,
  body.howItWork section.invest-product h2,
  body.howItWork .invest-process h2,
  body.howItWork section.synced-cloud-wrap .synced-cloud-bg h2,
  body.howItWork section.hwo-it-works-last h2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 15px;
  }
  body.howItWork section.invest-product .invest-pro-inner table thead tr th {
    font-size: 10px;
    padding: 10px 10px;
  }
  body.howItWork section.invest-product .invest-pro-inner table tbody td {
    font-size: 14px;
    padding: 10px 10px;
  }
  body.howItWork .hwo-it-works-bg .hwo-it-step:nth-child(2n) .step-content {
    padding: 0;
  }
  body.ourproject .caption,
  body.howItWork .caption {
    height: auto;
    left: 0;
    margin: auto 0;
    position: absolute;
    top: 0;
    vertical-align: middle;
    right: 0;
    margin-right: auto;
    margin-left: auto;
  }
  body.question .caption {
    height: auto;
    left: 0;
    margin: auto 0;
    max-width: 515px;
    position: absolute;
    top: 0;
    vertical-align: middle;
    right: 0;
    margin-right: auto;
    margin-left: auto;
  }
  body.ourproject .caption h1,
  body.question .caption h1,
  body.howItWork .caption h1 {
    font-size: 50px;
    text-align: center;
  }
  body.howItWork .item {
    padding: 20px;
    text-align: center;
  }
  body.howItWork .synced-slider .MuiBox-root {
    padding: 60px 0 0 0 !important;
  }
  body.howItWork section.invest-process .MuiContainer-fixed {
    max-width: inherit;
  }
  body.howItWork .hwo-it-works-last h2 {
    margin-bottom: 35px !important;
  }
  body.getStarted .get-started-block .MuiContainer-fixed,
  body.getStarted .get-started-section .signup-steps-wrap .MuiContainer-fixed {
    max-width: inherit;
  }
  body.getStarted .signup-getstarted .signup-img-wrap {
    text-align: center;
  }
  body.getStarted .signup-getstarted .content-rigth {
    margin-top: 20px;
    background: none !important;
    padding-left: 0 !important;
  }
  body.getStarted .signup-getstarted .content-left {
    padding-right: 0px !important;
  }
  body.howItWork
    .invest-process
    .invest-inner
    .process-list:nth-child(2n + 2)
    .process-item {
    margin-top: 0px;
  }

  /* About Section */
  body.about-us .container.MuiContainer-fixed {
    max-width: inherit;
  }
  body.about-us .caption,
  body.howItWork .caption,
  body.question .caption,
  body.ourproject .caption {
    height: auto;
    left: 0;
    position: relative;
    width: 100%;
    text-align: center;
  }
  body.about-us .caption h1,
  body.howItWork .caption h1,
  body.question .caption h1,
  body.ourproject .caption h1 {
    font-size: 30px;
    margin-top: 50px;
  }
  body.about-us .caption p,
  body.howItWork .caption p,
  body.question .caption p,
  body.ourproject .caption p {
    font-size: 16px;
  }
  body.about-us .inner.banner-section,
  body.howItWork .inner.banner-section,
  body.question .inner.banner-section,
  body.ourproject .inner.banner-section {
    min-height: inherit;
    height: 100%;
    margin-bottom: 30px;
  }
  body.about-us
    section.our-mission-section
    .our-mission-bg
    .mission-main
    .caption-main {
    margin-left: 0;
  }
  body.about-us .our-detail .detail-section .detail-item .detail-cont p {
    font-size: 16px;
  }
  body.about-us .our-detail .detail-section .detail-item:nth-child(2) {
    padding: 4px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  body.about-us .timeline-section .timeline-block .left-side {
    border: 1px solid #ccc;
    padding: 20px;
  }
  body.about-us .timeline-section .timeline-block .right-side {
    padding: 0;
  }
  body.about-us .timeline-section .timeline-block .step-lable {
    top: -20px;
  }
  body.about-us .timeline-img img {
    width: 100%;
  }
  body.about-us
    .timeline-section
    .timeline-block:nth-child(2n + 2)
    .MuiGrid-container
    > .right-side {
    padding: 0 0;
  }
  body.about-us
    .timeline-section
    .timeline-block:nth-child(2n + 2)
    .MuiGrid-container
    > .left-side {
    padding: 20px;
  }
  body.about-us .timeline-section .timeline-block:last-child .left-side {
    border: 1px solid #ccc !important;
  }
  body.about-us .timeline-section .timeline-block:last-child {
    margin-bottom: 0px;
  }
  body.about-us .timeline-section .timeline-main h2 {
    margin-bottom: 60px;
  }
  body.about-us .timeline-section .timeline-block {
    margin-bottom: 40px;
  }
  body.about-us
    .timeline-section
    .timeline-block:nth-child(2n + 2)
    .MuiGrid-container
    > .right-side {
    border: none;
  }
  body.about-us .solar-capacity .soalar-capacity-cont p {
    font-size: 16px;
  }
  body.about-us .timeline-section .timeline-main h2,
  body.about-us section.solar-capacity h2,
  body.about-us section.company-value h2 {
    font-size: 30px;
  }
  body.about-us .solar-capacity .soalar-capacity-cont h3 {
    font-size: 22px;
  }
  body.about-us .solar-capacity .soalar-capacity-cont p {
    min-height: 100px;
  }
  body.about-us .company-value .value-section .value-item:nth-child(2) {
    padding: 4px;
  }
  body.about-us .company-value .value-section .value-item .value-cont p {
    font-size: 16px;
  }
  body.about-us .mission-main .mis-img a p {
    font-size: 8px;
  }
  body.about-us .mission-main .mis-img a span {
    font-size: 10px;
  }
  body.about-us
    section.our-mission-section
    .our-mission-bg
    .mission-main
    .caption-main
    h2 {
    font-size: 30px;
  }
  body.about-us .company-value .value-section {
    margin: 0 0;
  }

  body.contact-us .banner-section .MuiContainer-fixed,
  body.contact-us .contact-us-section .MuiContainer-fixed {
    max-width: inherit;
  }
  body.contact-us section.contact-us-section .contact-us-bg .contactLeft {
    padding-right: 20px;
  }
  body.contact-us section.contact-us-section .contact-us-bg .contactRight {
    padding-left: 20px;
  }
  body.contact-us section.contact-us-section .contact-us-bg .footer-logo {
    text-align: center;
    display: block;
  }
  body.contact-us ul.social-icon {
    text-align: center;
  }
  body.contact-us section.contact-us-section .contact-us-bg {
    padding: 30px 30px 30px;
  }
  body.contact-us .inner.banner-section .container {
    position: relative;
  }
  body.contact-us .inner.banner-section {
    min-height: 150px;
  }
  body.contact-us .caption h1 {
    font-size: 34px;
    margin-top: 40px;
  }
  body.contact-us .caption {
    height: auto;
    left: 0;
    position: relative;
  }
  body.contact-us .caption p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 640px) {
  .who-we-are.left > ul {
    padding: 0 0;
    text-align: center;
  }
  .who-we-are-section .who-we-are-block .who-we-are.left > ul li:first-child {
    margin: 0 auto -20px !important;
    display: table;
    width: 180px;
  }
  .who-we-are-section .who-we-are-block .who-we-are.left > ul li {
    float: none;
    display: inline-block;
    margin: 0px -5px 0 !important;
    width: 180px !important;
  }
  .who-we-are-block .who-we-are .small-logo {
    display: none !important;
  }
  .who-we-are-block .who-we-are.left::after {
    display: none;
  }
  .who-we-are-block .who-we-are {
    width: 100%;
    position: relative;
    background: none;
    padding: 0;
    margin-top: 0px;
  }
  body.howItWork section.invest-product .invest-pro-inner table tbody tr td {
    white-space: inherit;
    font-size: 8px;
    padding: 5px;
  }
  body.howItWork .hwo-it-works-bg > h2,
  body.howItWork section.invest-product h2,
  body.howItWork .invest-process h2,
  body.howItWork section.synced-cloud-wrap .synced-cloud-bg h2,
  body.howItWork section.hwo-it-works-last h2 {
    font-size: 24px;
  }
  body.getStarted .get-started-section .get-started-block .content-left h2,
  body.getStarted .get-started-section .get-started-block .content-rigth h2 {
    font-size: 23px;
  }
  body.howItWork .product-content button {
    font-size: 13px;
  }
}

@media only screen and (max-width: 599px) {
  body.about-us .mission-main .mis-img .mis-inner {
    width: 100%;
  }
  body.about-us .meet-our .mis-img img {
    width: 100%;
  }
  body.about-us .mission-main .mis-img a span {
    font-size: 18px;
  }
  body.about-us .mission-main .mis-img a p {
    font-size: 14px;
  }
  body.about-us .mission-main .mis-img img {
    width: 100%;
  }
  body.about-us .timeline-section .timeline-block .time-caption p,
  body.about-us .solar-capacity .soalar-capacity-cont p,
  body.about-us .company-value .value-section .value-item .value-cont p,
  body.about-us
    section.our-mission-section
    .our-mission-bg
    .mission-main
    .caption-main
    p {
    font-size: 14px;
  }
  body.about-us section.our-mission-section .our-mission-bg {
    padding: 20px;
  }

  body.contact-us .banner-section .MuiContainer-fixed,
  body.contact-us .contact-us-section .MuiContainer-fixed {
    max-width: inherit;
  }
  body.contact-us section.contact-us-section .contact-us-bg .contactLeft {
    padding-right: 4px;
  }
  body.contact-us section.contact-us-section .contact-us-bg .contactRight {
    padding-left: 4px;
    border: none;
  }
  body.contact-us section.contact-us-section .contact-us-bg .footer-logo {
    text-align: center;
    display: block;
  }
  body.contact-us ul.social-icon {
    text-align: center;
    margin-bottom: 30px !important;
  }
  body.ourproject .solar-installations .hwo-it-works-bg {
    padding: 60px 20px;
  }
}

@media only screen and (max-width: 540px) {
  body.howItWork .invest-product .invest-pro-inner .MuiTableContainer-root {
    overflow-x: scroll !important;
  }
  body.ourproject .inner.banner-section,
  body.question .inner.banner-section,
  body.howItWork .inner.banner-section,
  body.question .inner.banner-section {
    min-height: 150px;
    word-break: break-all;
  }
  body.ourproject .caption h1,
  body.question .caption h1,
  body.howItWork .caption h1 {
    font-size: 30px;
    text-align: center;
    margin-top: 50px;
  }
  body.howItWork .hwo-it-works-bg {
    padding: 50px 30px;
  }
  body.howItWork
    .hwo-it-works-bg
    .hwo-it-step-block
    .hwo-it-step
    .step-content
    p {
    font-size: 16px;
  }
  body.howItWork .hwo-it-works-bg .hwo-it-step:nth-child(2n) .step-content {
    height: auto;
  }
  ul.contact li p {
    font-size: 13px;
  }
  ul.contact li:first-child p {
    margin-top: 12px;
  }
}
/* @media screen and (min-device-width: 960px) and (max-device-width: 1199px) {
  button.get_started {
    font-weight: 700;
    height: 60px;
    line-height: 40px;
    font-size: 18px;
    width: auto;
    min-width: auto;
    padding: 0 26px;
    color: #fff;
    box-shadow: none;
    background: #fbb116;
  }
} */
@media only screen and (max-width: 480px) {
  .cardContent {
    width: auto;
    max-width: 100%;
    height: auto;
  }
  body.dashboard .greeting {
    margin-top: '1vh';
  }
  body.dashboard .tabs {
    margin: -22;
    padding-top: 20;
  }
  body.ourproject .inner.banner-section,
  body.howItWork .inner.banner-section,
  body.question .inner.banner-section {
    min-height: 150px;
  }
  button.get_started {
    font-weight: 700;
    height: 60px;
    line-height: 40px;
    font-size: 18px;
    width: auto;
    min-width: auto;
    padding: 0 26px;
    color: #fff;
    box-shadow: none;
    background: #fbb116;
  }
}
@media only screen and (max-width: 320px) {
  body.dashboard .tabs {
    margin: -22;
    padding-top: 20;
  }
  body.ourproject .inner.banner-section,
  body.question .inner.banner-section,
  body.howItWork .inner.banner-section,
  body.question .inner.banner-section {
    min-height: 150px;
  }
  button.get_started {
    font-weight: 700;
    height: 60px;
    line-height: 40px;
    font-size: 18px;
    width: auto;
    min-width: auto;
    padding: 0 26px;
    color: #fff;
    box-shadow: none;
    background: #fbb116;
  }
}
